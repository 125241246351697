

.viewerControls {
  display: 'flex';
  flex: 1;
  justify-content: 'flex-start';
  padding-left: 7;
  padding-bottom: 1
}

.viewerControls__email_field {
  padding-left: 7;
  padding-right: 7;
  padding-bottom: 2
}
.emailField {
  height: 40px;
}
.viewerControls__email_Buttons {
  padding-left: 7;
  padding-right: 7;
  padding-bottom: 2
}
.viewerControls__email_Buttons_stack {
  display: 'flex' !important;
  justify-content: 'space-between' !important;
}

.pdfPageButtons_grid {
  display: 'flex' !important;
  flex: 1 !important;
  justify-content: 'center' !important;
}

.fullscreen_toolBar_iconButton {
  color: '#626679 !important';
  background-color: #fff !important;
  border-radius: 0 !important;
}
