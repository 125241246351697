.lightmodeDatagrid .dx-datagrid .dx-datagrid-headers .dx-datagrid-text-content {
  font-size: 12px !important;
  font-weight: 800 !important;
  color: black;
}

.lightmodeDatagrid .dx-datagrid .dx-cell {
  padding: 0px 10px 0px 10px !important;
}


#dataGrid > div > div.dx-datagrid-rowsview.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic.dx-fixed-columns > div.dx-scrollable-wrapper > div > div > div > table > tbody {
  padding: 0px 10px 0px 10px !important;
}


#dataGrid > div > div.dx-datagrid-rowsview.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic.dx-fixed-columns > div.dx-scrollable-wrapper > div > div > div > table > tbody > tr > td {
  padding: 0px 10px 0px 10px !important;
}


#dataGrid > div > div.dx-datagrid-rowsview.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic > div > div > div > div > table > tbody > tr {
  height: 32px !important;
}

/* Not sure why this is here */
/* td[role=columnheader] {
  text-align: left!important
} */

div.dx-datagrid-scrollable-simulated:nth-child(1) > table:nth-child(1) > tbody:nth-child(2) > tr:nth-child(1) > td:nth-child(1) {
  text-align: center !important;
}
