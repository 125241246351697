.darkmodeDatagrid .dx-datagrid,
.darkmodeDatagrid .dx-datagrid .dx-datagrid-header-panel,
.darkmodeDatagrid .dx-datagrid .dx-datagrid-header-panel .dx-toolbar,
.darkmodeDatagrid .dx-datagrid .dx-datagrid-headers {
  background-color: transparent !important;
  color: #eeeeee !important;
  border-color: rgb(66, 66, 66) !important;
}

.darkmodeDatagrid .dx-datagrid .dx-datagrid-headers .dx-datagrid-text-content {
  font-size: 12px !important;
  font-weight: 800;
}

.darkmodeDatagrid .dx-scrollable-content {
  background-color: #1d1d1d !important;
}

.darkmodeDatagrid .dx-datagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td:hover > .dx-datagrid-text-content {
  color: rgb(238, 238, 238) !important;
}

.darkmodeDatagrid .dx-datagrid .dx-command-select {
  background-color: transparent !important;
  color: #eeeeee !important;
  border: none;
}

.darkmodeDatagrid .dx-datagrid .dx-datagrid-header-panel .dx-texteditor-input-container input {
  border: 1px solid rgb(165, 165, 165) !important;
  border-radius: 5px;
  color: #fff;
}

.darkmodeDatagrid .dx-datagrid .makeStyles-root-1 {
  border: 1px solid rgba(244, 243, 243, 0.26) !important;
}

.darkmodeDatagrid .dx-datagrid .dx-row > td {
  color: rgba(231, 227, 252, 0.68) !important;
}

.darkmodeDatagrid .dx-toolbar-button.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  color: #e0e0e08a !important;
}

.darkmodeDatagrid .dx-info {
  color: #fff !important;
}

.darkmodeDatagrid .dx-datagrid .dx-searchbox .dx-icon-search::before {
  color: #ebebeb !important;
}

.darkmodeDatagrid .dx-datagrid .dx-toolbar-items-container .dx-icon > i {
  color: #fff !important;
}

.darkmodeDatagrid .dx-datagrid .dx-col-fixed {
  background-color: #1d1d1d !important;
}

.darkmodeDatagrid .dx-datagrid .dx-row-lines .dx-state-hover > td {
  color: #eeeeee !important;
}

.darkmodeDatagrid .dx-datagrid .dx-row,
.darkmodeDatagrid .dx-datagrid .dx-row-lines > td,
.darkmodeDatagrid .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-color: rgb(66, 66, 66) !important;
}

.darkmodeDatagrid .dx-datagrid .dx-header-filter-empty {
  color: #cecece !important;
}
.darkmodeDatagrid .dx-datagrid .dx-checkbox-icon {
  border-color: rgb(238, 238, 238) !important;
}

/* Not sure why this is here */
/* td[role='columnheader'] {
  text-align: left !important;
} */

.darkmodeDatagrid td[aria-label='Column Actions'],
.darkmodeDatagrid td[aria-describedby$='-fixed'] {
  color: #eeeeee !important;
  background-color: #121212 !important;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.06943755299006493),
    rgba(255, 255, 255, 0.06943755299006493)
  ) !important;
}

.darkmodeDatagrid .dx-datagrid .dx-datagrid-rowsview .dx-data-row td.dx-pointer-events-none {
  border-right: none !important;
  border-left: none !important;
}

.darkmodeDatagrid .dx-datagrid .dx-datagrid-rowsview .dx-freespace-row td.dx-pointer-events-none {
  border-right: none !important;
  border-left: none !important;
}

div.dx-datagrid-scrollable-simulated:nth-child(1)
  > table:nth-child(1)
  > tbody:nth-child(2)
  > tr:nth-child(1)
  > td:nth-child(1) {
  text-align: center !important;
}

.darkmodeDatagrid tr.dx-row.dx-data-row.dx-row-lines {
  background-color: #1d1d1d !important;
}

.darkmodeDatagrid tr.dx-row.dx-data-row.dx-row-lines > .dx-command-select.dx-editor-cell.dx-editor-inline-block {
  border-right: 2px solid rgb(66, 66, 66) !important;
}

.dx-texteditor-input-container input::placeholder {
  color: #fff !important;
}

.darkmodeDatagrid .dx-datagrid-rowsview.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-fixed-columns {
  background-color: #1d1d1d !important;
}

.darkmodeDatagrid .dx-loadpanel,
.darkmodeDatagrid .dx-loadpanel-wrapper,
.darkmodeDatagrid .dx-load-panel {
  background-color: #1d1d1d !important;
}

.darkmodeDatagrid div.dx-page {
  color: #fff !important;
}

.darkmodeDatagrid span.dx-datagrid-nodata {
  color: #fff !important;
}

.darkmodeDatagrid span.dx-sort.dx-sort-up {
  color: #fff !important;
}

.darkmodeDatagrid span.dx-sort.dx-sort-down {
  color: #fff !important;
}

.darkmodeDatagrid div.dx-sort-indicator {
  color: #bfbfbf !important;
}
