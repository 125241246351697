@import url('./dataGrid.css');
@import url('./luckysheetcustom.css');
@import url('./mappingQueryBuilder.css');

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 800;
  src: local('Gotham Rounded'), url('https://fonts.cdnfonts.com/s/22255/Gotham Rounded Bold.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 300;
  src: local('Gotham Rounded'), url('https://fonts.cdnfonts.com/s/22255/Gotham Rounded Light.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: italic;
  font-weight: 300;
  src: local('Gotham Rounded'),
    url('https://fonts.cdnfonts.com/s/22255/Gotham Rounded Light Italic.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: normal;
  font-weight: 350;
  src: local('Gotham Rounded'), url('https://fonts.cdnfonts.com/s/22255/Gotham Rounded Medium.woff') format('woff');
}

@font-face {
  font-family: 'Gotham Rounded';
  font-style: italic;
  font-weight: 350;
  src: local('Gotham Rounded'),
    url('https://fonts.cdnfonts.com/s/22255/Gotham Rounded Medium Italic.woff') format('woff');
}

html,
body {
  font-size: 14px;
  min-height: 100%;
}

#__next {
  height: 100%;
}

code {
  font-family: 'Quicksand', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  padding: 0.1rem 0.4rem;
  font-size: 90%;
  color: #007a9c;
  border-radius: 0.1335rem;
}

code:not([class*='language-']):before,
code:not([class*='language-']):after {
  content: '`';
}

code[class*='language-'] {
  padding: 0;
}

.listButton.Mui-selected {
  border-radius: 4px;
  padding-bottom: 0;
}

/* @import url('../node_modules/jquery-ui/themes/base/all.css');
@import url('../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.common.css');
@import url('../node_modules/@devexpress/analytics-core/dist/css/dx-analytics.light.css');
@import url('../node_modules/devexpress-reporting/dist/css/dx-webdocumentviewer.css');
@import url('../node_modules/devexpress-reporting/dist/css/dx-reportdesigner.css'); */
@import url('../node_modules/devextreme/dist/css/dx.material.blue.light.compact.css');

.gjs-one-bg {
  background-color: #fff !important;
}

/* grapejs overrides */

.gjs-one-bg {
  background-color: #fff !important;
}

.gjs-field {
  background-color: #fff !important;
  border: #ccc;
  border-style: solid;
  color: rgba(0, 0, 0, 0.68);

  border-width: 1px;
}

.gjs-one-color {
  color: #fff !important;
}

.gjs-one-color-h:hover {
  color: #fff !important;
}

.gjs-two-bg {
  background-color: #444 !important;
}

.gjs-two-color {
  color: #444 !important;
}

.gjs-two-color-h:hover {
  color: #444 !important;
}

.gjs-three-bg {
  background-color: #2b2d42 !important;
}

.gjs-three-color {
  color: #2b2d42 !important;
}

.gjs-three-color-h:hover {
  color: #2b2d42 !important;
}

.gjs-four-bg {
  background-color: #2b2d42 !important;
}

.gjs-four-color {
  color: #2b2d42 !important;
}

.gjs-four-color-h:hover {
  color: #2b2d42 !important;
}

.gjs-danger-bg {
  background-color: #dd3636 !important;
}

.gjs-danger-color {
  color: #dd3636 !important;
}

.gjs-danger-color-h:hover {
  color: #dd3636 !important;
}

.gjs-bg-main,
.gjs-sm-colorp-c,
.gjs-off-prv {
  background-color: #444 !important;
}

.gjs-color-active {
  color: #f8f8f8 !important;
  fill: #f8f8f8 !important;
}

.gjs-color-warn {
  color: #ffca6f !important;
  fill: #ffca6f !important;
}

.gjs-color-hl {
  color: #71b7f1 !important;
  fill: #71b7f1 !important;
}

.pdf-document {
  overflow-y: scroll;
  overflow-x: scroll;
}

.gjs-sm-sector-caret {
  width: 24px !important;
  height: 24px !important;
}

.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  flex-direction: column;
  bottom: 0;
  align-items: flex-end !important;
}

.gjs-sm-clear {
  max-width: 20px !important;
  max-height: 20px !important;
}

.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.dx-htmleditor-content table {
  width: 50%;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
  display: inline-block;
  margin-right: 40px;
}

.option > .dx-selectbox,
.option > .label {
  display: inline-block;
  vertical-align: middle;
}

.option > .label {
  margin-right: 10px;
}

.tree-list-header-row > td > div > div.dx-checkbox-container > span {
  background-color: #d7d7d7;
  display: contents !important;
}

/* Hide checkbox at system module grouping level */
.tree-list-header-row > td > div > div.dx-checkbox {
  display: none;
}

/* Remove padding for system module group text when checkbox is hidden */
.tree-list-header-row > td > div {
  padding-right: 0 !important;
}

.tree-list-disabled-option > div > div.dx-checkbox-container > span {
  background-color: #d7d7d7;
  display: contents !important;
}

.productfruits--lifering-modal {
  top: 20px !important;
  right: 25px !important;
  left: auto !important;
  bottom: auto !important;
}

.productfruits--lifering {
  top: 220px !important;
  right: 25px;
  left: auto;
  bottom: auto;
}

.productfruits--card-footer-steps-counter {
  display: none;
}
