.dx-datagrid-header-panel .dx-toolbar-items-container {
  min-height: 55px;
}

.dx-toolbar-button.dx-toolbar-text-auto-hide .dx-button .dx-icon {
  width: 24px !important;
  height: 24px !important;
  font-size: 25px !important;
  color: rgba(0, 0, 0, 0.54) !important;
  margin-right: 0;
  margin-left: 0;
}

.dx-pager {
  padding: 5px 5px !important
}

.dx-pager .dx-pages .dx-page-indexes {
  margin-right: 30px !important;
}

.dx-datagrid-filter-panel {
  padding: 9px 11px !important;
}

.dx-texteditor.dx-editor-outlined {

  border-radius: 5px !important;

}

.dx-texteditor.dx-editor-outlined {
  width: 200px !important;
  margin-left: 0px !important;
  font-family: 'Quicksand';
  height:'37px' !important;
}

.dx-datagrid-headers {
  font-family: 'Quicksand';
  font-size: '0.8rem' !important;
  background-color: rgb(249, 250, 252);

}

.dx-datagrid {
 border-radius: 5px !important;
 font-family: 'Quicksand' !important
}


